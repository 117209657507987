
import React, { Component } from "react"

export class AScrollIntoView extends Component {

    constructor(props) {
        super(props);
        this.props = props
    }

    render() {

        const click = () => {
            const el = document.getElementById(this.props.r)
            el.scrollIntoView({ behavior: "smooth"})
        }

        return (
            <a onClick={click} className={this.props.className}>{this.props.children}</a>
        )
    }
}