import React from "react"

import pej from '../images/pej.jpg'
import sr from '../images/sr.jpg'
import xr from '../images/xr.jpg'
import ms from '../images/ms.jpg'
import jfl from '../images/jfl.jpg'
import ma from '../images/ma.png'
import dna from '../images/dna.svg'
import logo from '../images/logo.svg'
import udes from '../images/udes.png'

import "./home.css"


import { AScrollIntoView } from '../browserOnly'

const imageLink =
    path => `https://bulma-css-bulmaplay.appseed.us/${path}`


const carte = ({nom, titre, icon, dept}) =>
    <div className="column">
        <div className="feature-card is-bordered has-text-centered revealOnScroll delay-1" data-animation="fadeInLeft">
            <div className="card-title">
                <h4>{nom}</h4>
            </div>
            <div className="card-icon">
                {icon}
            </div>
            <div className="card-text">
                <p>{titre}</p>
            </div>
            <div className="card-text">
                <p>{dept}</p>
            </div>
        </div>
    </div>

const page = () => <>
    <section className="hero is-fullheight is-default is-bold" id="top">
            <div className="hero-head">
            <nav className="navbar is-fresh no-shadow is-fixed-top" role="navigation" aria-label="main navigation" style={{background:"white"}}>
                    <div className="container">
                        <div className="navbar-brand">
                            <a className="navbar-item" href="/">
                                <img src={logo}
                                    alt="" width="88" height="40"/>
                            </a>
                        </div>

                        <div id="navbar-menu" className="navbar-menu is-static">



                            <div className="navbar-end">
                                <AScrollIntoView r="services" className="navbar-item is-secondary">
                                    Services
                                </AScrollIntoView>
                                <AScrollIntoView r="equipe" className="navbar-item is-secondary">
                                    Equipe
                                </AScrollIntoView>
                                <div className="navbar-item has-dropdown is-hoverable">
                                    <a className="navbar-link">
                                        Outils
                                    </a>

                                    <div className="navbar-dropdown">
                                        <a className="navbar-item" href="http://omero.ccs.usherbrooke.ca/">
                                            Client OMERO
                                        </a>
                                        <a className="navbar-item" href="http://bioinfo.ccs.usherbrooke.ca/emboss-explorer/">
                                            EMBOSS Explorer
                                        </a>
                                        <a className="navbar-item" href="http://bioinfo.ccs.usherbrooke.ca/cgi-bin/CrispyCrunch/index.pl">
                                            CrispyCRISPR
                                        </a>
                                    </div>
                                </div>
                                <AScrollIntoView r="contact" className="navbar-item is-secondary">
                                    Contact
                                </AScrollIntoView>

                            </div>
                        </div>
                    </div>
                </nav>
            </div>

            <div className="hero-body">
                <div className="container">
                    <div className=" is-6 landing-caption has-text-centered pb-50 mb-50">
                        <img alt="photo" className="lazy" src={dna} />
                    </div>
                    <div className=" is-vcentered ">

                        <div className=" is-6 landing-caption has-text-centered mb-50 ">

                            <h1 className="title is-1 is-bold is-spaced">
                                Platforme bio-informatique
                            </h1>
                            <p className="subtitle is-2 ">
                                de l'Université de Sherbrooke
                            </p>
                        </div>
                        <div className="content mt-50 huge-pb">
                            <p className="is-size-5 has-text-centered">
                                Vu l’explosion des données en génomique, les besoins d’analyses bio-informatique sont en forte croissance depuis la dernière décennie.
                                Le service bio-informatique a pour mission d’offrir aux chercheurs provenant des domaines académique et privé la possibilité de valider
                                leurs hypothèses à l’aide de solutions bio-informatiques spécifiquement développées pour leurs besoins.
                            </p>
                        </div>

                    </div>
                    <div className=" is-6 landing-caption has-text-centered pb-50 mb-50">
                        <img alt="photo" className="lazy" src={udes} />
                    </div>
                </div>
            </div>
        </section>

        <section id="services" className="section section-feature-grey is-medium">
            <div className="container">
                <div className="columns">
                    <div className="column is-centered-tablet-portrait">
                        <h2 className="title section-title">Solutions spécifiques </h2>
                        <h3 className="subtitle is-4 is-muted">à vos besoins</h3>
                        <div className="divider"></div>
                    </div>
                    <div className="column is-8 ">

                        <article className="media icon-box">
                            <div className="media-content mt-25">
                                <div className="content">
                                    <p>
                                        <span className="icon-box-title">Analyse de vos besoins et estimation de coûts</span>
                                        <span className="icon-box-text">Solution efficace à vos problèmes d'analyses à un coût très concurrentiel.
                                        Travail de pair avec nos clients pour bien comprendre leur domaine de recherche et la problématique liée à l'analyse de leur données.</span>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className="media icon-box">
                            <div className="media-content mt-25">
                                <div className="content">
                                    <p>
                                        <span className="icon-box-title">Analyse de données de séquençage à haut débit et de protéomique</span>
                                        <span className="icon-box-text">De multiples pipelines d'analyse vous sont déjà disponibles: RNA-seq, CHIP-seq, DNA-seq, Microbiome, MS-MS, etc.
                                        Nous contacter pour une liste complète. </span>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className="media icon-box">
                            <div className="media-content mt-25">
                                <div className="content">
                                    <p>
                                        <span className="icon-box-title">Analyses bio-statistiques</span>
                                        <span className="icon-box-text">Analyse de données cliniques, d'enrichissement de gènes, de motif, heatmap. clustering, etc.</span>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className="media icon-box">
                            <div className="media-content mt-25">
                                <div className="content">
                                    <p>
                                        <span className="icon-box-title">Développement de scripts d'analyses et d'outils bioinformatiques personnalisés</span>
                                    </p>
                                </div>
                            </div>
                        </article>

                        <article className="media icon-box">
                            <div className="media-content mt-25">
                                <div className="content">
                                    <p>
                                        <span className="icon-box-title">Optimisation et parallélisation d'algorithmes</span>
                                    </p>
                                </div>
                            </div>
                        </article>

                    </div>
                </div>
            </div>
        </section>

        <section id="equipe" className="section  is-medium">
            <div className="container">
                <div className="title-wrapper has-text-centered">
                    <h2 className="title is-2">Une équipe sur mesure</h2>
                    <h3 className="subtitle is-5 is-muted">L'analyse de vos besoins est entre bonnes mains</h3>
                    <div className="divider is-centered"></div>
                </div>

                <div className="content-wrapper">
                    <div className="title-wrapper has-text-centered pb-20"  >
                        <h2 className="title is-3">Direction</h2>
                    </div>
                    <div className="columns">
                        {carte({
                            nom: "Pierre-Étienne Jacques",
                            icon: <img alt="photo" className="lazy" src={pej} />,
                            dept:  "Département de Biologie",
                            titre: "Professeur adjoint",
                            link: <a target="_blank" href="" className="button btn-align-md accent-btn raised">Get Code</a>
                        })}
                        {carte({
                            nom: "Sébastien Rodrigue",
                            icon: <img alt="photo" className="lazy" src={sr} />,
                            dept:  "Département de Biologie",
                            titre: "Professeur agrégé",
                            link: <a target="_blank" href="" className="button btn-align-md accent-btn raised">Get Code</a>
                        })}
                        {carte({
                            nom: "Xavier Roucou",
                            icon: <img alt="photo" className="lazy" src={xr} />,
                            dept:  "Département de Biochimie",
                            titre: "Professeur titulaire",
                            link: <a target="_blank" href="" className="button btn-align-md accent-btn raised">Get Code</a>
                        })}
                        {carte({
                            nom: "Michelle Scott",
                            icon: <img alt="photo" className="lazy" src={ms} />,
                            dept:  "Département de Biochimie",
                            titre: "Professeure agrégée",
                            link: <a target="_blank" href="" className="button btn-align-md accent-btn raised">Get Code</a>
                        })}
                    </div>
                    <div className="title-wrapper has-text-centered pb-20">
                        <h2 className="title is-3">Bio-informaticien</h2>
                    </div>
                    <div className="columns">
                        {carte({
                            nom: "Jean-Francois Lucier",
                            icon: <img alt="photo" className="lazy" src={jfl} />,
                            dept:  "Département de Biologie",
                            titre: "Professionnel de recherche",
                            link: <a target="_blank" href="" className="button btn-align-md accent-btn raised">Get Code</a>
                        })}
                        {carte({
                            nom: "Mariano Avino",
                            icon: <img alt="photo" className="lazy" src={ma} />,
                            dept:  "Département de Biochimie",
                            titre: "Professionnel de recherche",
                            link: <a target="_blank" href="" className="button btn-align-md accent-btn raised">Get Code</a>
                        })}
                    </div>
                </div>
            </div>

        </section>

        <section id="contact" className="section section-light-grey is-medium" tabIndex="-1">
            <div className="container">
                <div className="title-wrapper has-text-centered">
                    <h2 className="title is-2 is-spaced">Contactez-nous</h2>
                    <h3 className="subtitle is-5 is-muted">pour en connaître davantage sur les services offerts par la platforme.</h3>
                    <div className="divider is-centered"></div>
                </div>

                <div className="content-wrapper">
                    <div className="content has-text-centered">
                                <p className=" is-medium" >Jean-Francois Lucier</p>
                                <p className=" is-medium" >Téléphone: (819) 821-8000 ext: 65806 </p>
                                <p className=" is-medium" >Courriel: jean-francois.lucier[at]usherbrooke.ca</p>
                    </div>
                </div>
            </div>
        </section>

    <div className="sticky-bottom">
        <AScrollIntoView r="top" className="button goto-top">Back to Top</AScrollIntoView>

    </div>
</>


export default page
